import client from "@/api";
import store from "@/store";
import {
  _rotaVtexDashboardPedidos,
  _rotaVtexDashboardProdutos,
  _rotaVtexDashboardMarcas,
} from "@/api/_caminhosApi";

export function listarNumerosPedidos() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("dashboard/RESET_PEDIDOS", null);
  client
    .get(`${_rotaVtexDashboardPedidos()}`)
    .then((resp) => {
      if (resp.status === 200) {
        store.commit("dashboard/SET_PEDIDOS", resp.data);
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarNumerosProdutos(order_by = 'revenue') {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("dashboard/RESET_PRODUTOS", null);
  client
    .get(`${_rotaVtexDashboardProdutos()}`, {
      params: {
        order_by: order_by,
      }
    })
    .then((resp) => {
      if (resp.status === 200) {
        store.commit("dashboard/SET_PRODUTOS", resp.data);
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarNumerosMarcas() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("dashboard/RESET_MARCAS", null);
  client
    .get(`${_rotaVtexDashboardMarcas()}`)
    .then((resp) => {
      if (resp.status === 200) {
        store.commit("dashboard/SET_MARCAS", resp.data);
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
