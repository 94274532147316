<template>
  <div class="produtos">
    <div class="box-filter">
      <FilterAffiliate @changeAffiliate="changeAffiliate" :selectMultiple="false" />
      <FilterSeller @changeSeller="changeSeller" :selectMultiple="false" />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div class="primary">
      <TopProducts />
      <div class="box table">
        <div class="box__header">
          <HeaderGraphic :data="tableData" />
          <a class="btn-download" @click="downloadCSV()" download>Download CSV</a>
        </div>
        <div class="painel">
          <b-table :items="tableData.items"></b-table>
          <paginate
            v-model="currentPage"
            :page-count="lastPage"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import FilterSeller from "@/components/filter-seller/FilterSeller.vue";
import FilterAffiliate from "@/components/filter-affiliate/FilterAffiliate.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import TopProducts from "@/components/products/TopProducts.vue";
import { numberToReal } from "@/utils/money";
import { listarCategoriasMaisVendidas } from "@/api/categorias";
import { listarProdutos, getCSV } from "@/api/produtos";
import { listarNumerosProdutos } from "@/api/dashboard";

export default {
  components: {
    HeaderGraphic,
    FilterDatepicker,
    FilterSeller,
    FilterAffiliate,
    TopProducts,
  },
  data() {
    return {
      tableData: {},
      perPage: 15,
      currentPage: 1,
      sellerIds: null,
      affiliateIds: null,
      start: null,
      end: null,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState({
      accountActive: state => state.accounts.accountActive,
      getProdutos: state => state.produtos.produtos,
      lastPage: state => state.produtos.produtos_last_page,
    }),
  },
  methods: {
    listarCategoriasMaisVendidas,
    listarProdutos,
    getCSV,
    listarNumerosProdutos,
    downloadCSV() {
      this.getCSV(this.start, this.end);
    },
    loadData() {
      this.listarCategoriasMaisVendidas();
      this.listarNumerosProdutos();
      this.listarProdutos(this.start, this.end, this.perPage, this.currentPage, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds
      });
    },
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      this.listarProdutos(start, end, this.perPage, this.currentPage, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds
      });
    },
    changeSeller(sellerIds) {
      this.currentPage = 1;
      this.sellerIds = sellerIds;
      this.listarProdutos(this.start, this.end, this.perPage, this.currentPage, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds
      });
    },
    changeAffiliate(affiliateIds) {
      this.currentPage = 1;
      this.affiliateIds = affiliateIds;
      this.listarProdutos(this.start, this.end, this.perPage, this.currentPage, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds
      });
    },
    loadTable(value) {
      const items = [];
      if (value.data) {
        value.data.map(line => {
          items.push({
            id: `${line.productId}`,
            produto: `${line.name}`,
            categoria: `(${line.categoriesIds}) ${line.categoriesNames}`,
            marca: line.brandName,
            "Preço": numberToReal(line.price),
            quantidade: line.qtd_vendidos,
            pedidos: line.qtd_pedidos,
            receita: numberToReal(line.revenue),
            "Ticket Médio": numberToReal(line.ticket_medio),
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Detalhamento no período selecionado",
        },
        items,
      };
      return (this.tableData = data);
    },
  },
  watch: {
    getProdutos(newData) {
      if (newData) {
        this.loadTable(newData);
      }
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      this.listarProdutos(this.start, this.end, this.perPage, this.currentPage, {
        seller_id: this.sellerIds,
        affiliate_id: this.affiliateIds
      });
    },
    accountActive(data) {
      if (data) this.loadData();
    },
  },
};
</script>

<style lang="scss">
.produtos {
  .box-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;

    > div {
      margin: 0 20px;
    }
  }

  .categorias {
    height: 100%;
    .page-container {
      margin: 30px 0;
    }
  }

  .small {
    max-width: 600px;
    margin: 150px auto;
  }

  .primary {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    max-width: calc(50% - 7.5px);
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 18px;
    box-shadow: 0 3px 6px #c3d0e029;
    border-radius: 20px;
    height: 400px;
    &:nth-child(2) {
      margin-right: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    .painel {
      position: relative;
      height: 300px;
      display: block;
      overflow: hidden;
      .table {
        td[aria-colindex="6"],
        td[aria-colindex="7"] {
          white-space: nowrap;
          text-align: center;
        }
        td[aria-colindex="5"],
        td[aria-colindex="8"],
        td[aria-colindex="9"] {
          white-space: nowrap;
          text-align: right;
        }
      }
    }
    &.table {
      margin: 0;
      max-width: calc(65% - 15px);
      margin-left: 15px;
      height: auto;
      display: inline-table;
      &:after {
        content: "";
        display: block;
      }

      &:first-child {
        max-width: calc(50% - 15px);
        margin: 0;
        margin-right: 15px;
        display: inline-table;
      }

      .painel {
        height: auto;
        overflow-x: auto;
      }
    }
  }

  @media only screen and (max-width: 1350px) {
    .primary {
      flex-direction: column;
    }
    .box-filter {
      justify-content: flex-end;
    }
    .box {
      &.table {
        max-width: 100%;
        margin-top: 30px;
        margin-left: 0;
        padding: 25px;
        overflow-x: scroll;
        display: block;
        &:first-child {
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }
}
</style>
