<template>
  <div class="product__content">
    <div class="product__boxImage">
      <figure class="product__figure">
        <img :src="produto.imageUrl" />
      </figure>
    </div>
    <div class="product__description">
      <div>
        <div class="product__infos --lg">
          <!-- <label>Produto</label> -->
          <p>{{ `${produto.name} (${produto.productId})` }}</p>
        </div>
        <div class="product__infos --qtd">
          <label>Vendidos</label>
          <p>{{produto.qtd_vendidos}}</p>
        </div>
        <div class="product__infos --qtd">
          <label>Receita</label>
          <p>
            {{produto.revenue | numberToReal}} 
            <span class="badge badge--success"
              title="Participação nas vendas com base no período"
              v-b-tooltip.hover.top>{{produto.period_percent | percentage(false) }}</span>
          </p>
        </div>
      </div>

      <div>
        <div class="product__infos">
          <label>Categoria</label>
          <p>{{produto.categoriesNames}}</p>
        </div>
        <div class="product__infos">
          <label>Marca</label>
          <p>{{produto.brandName}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberToReal } from "@/utils/money";
import { percentage } from "@/utils/masks";
export default {
  filters: {numberToReal, percentage},
  props: ["produto"]
};
</script>

<style lang="scss">
.product {
  &__content {
    border-top: 1px solid $lightGray;
    display: flex;
    margin: 2.5vh 0 0;
    padding: 2.5vh 0 0;

    &:first-child {
      border: 0;

      &__figure {
        border-color: #FFC400;
      }

      &__boxImage {
        position: relative;

        &::before {
          content: '';
          width: 2.2vw;
          height: 2.9vw;
          position: absolute;
          z-index: 1;
          top: 3.5vw;
          right: 0;
          background: url('~@/assets/images/icones/icon-award.svg') no-repeat center;
          background-size: cover;
        }
      }
    }
  }

  &__description {
    width: 75%;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      width: 65%;
      &:last-child {
        width: 30%;
      }
    }
  }

  &__infos {
    &.--qtd {
      align-items: center;
      display: flex;

      p {
        @include font-size(1.7);
        margin-left: 10px;
      }
    }

    label {
      color: $cinza-interno;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      color: $cor-escura;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }

  &__boxImage {
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
  }

  &__figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 3px solid $lightGray;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(2, 107, 126, 0.1);

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__name {
    min-width: 5.1rem;
    font-family: $font__main;
    @include font-size(0.82);
    color: $gray;
    display: block;
    line-height: 0.8vw;
  }

  &__info {
    font-family: $font__main;
    @include font-size(0.76);
    color: $dark;
  }

  &__qtd {
    font-family: $font__main;
    color: $dark;
    margin-left: 0;
    line-height: 2rem; 
  }

  &__highlight {
    display: flex;
  }
}
</style>
