<template>
  <div class="filter-affiliate">
    <p>Canal:</p>
    <div>
      <b v-if="!loaded">Carregando...</b>
			<multiselect
				v-if="loaded && affiliatesList.length > 0"
				class="filter-affiliate__select"
				placeholder="Digite aqui para filtrar"
				noOptions="Sem resultados"

				label="affiliateId"
				track-by="affiliateId"
				
				v-model="selected"
				:options="affiliatesList"
				:searchable="true"
				:allow-empty="true"
				:show-labels="false"
				:hide-selected="selectMultiple"

				:multiple="selectMultiple"
				:close-on-select="!selectMultiple"

				@remove="setSelected"
				@input="setSelected"
			></multiselect>

			<b-button
				v-if="selected !== null"
				variant="outline-dark"
				size="sm"
				@click="clearFilter">
				limpar
			</b-button>
			
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getAffiliatesList } from "@/api/afiliados";
export default {
  data() {
    return {
			selected: null,
			loaded: false,
    };
  },
	props: [ 'selectMultiple' ],
  mounted() {
    this.getAffiliatesList();
  },
  computed: {
    ...mapState({
      affiliatesList: state => state.vendas.affiliatesList
    })
  },
  methods: {
    getAffiliatesList,
		setSelected() {
			if (this.selectMultiple === true) {
				if (this.selected.length > 0) {
					var ids = this.selected.map(function(item) {
						return item.affiliateId;
					});
					this.$emit("changeAffiliate", ids.join(','));
				} else {
					this.$emit("changeAffiliate", null);
				}
			} else {
					this.$emit("changeAffiliate", this.selected.affiliateId);
			}
		},
		clearFilter() {
			this.selected = null;
			this.$emit("changeAffiliate", null);
		}
  },
  watch: {
    affiliatesList() {
			this.loaded = true;
    },
  },
};
</script>

<style lang="scss">
.filter-affiliate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
	&__select {
		min-width: 200px;
		margin: 0 5px;
	}
  > div {
		align-items: center;
		display: flex;
    font-size: 14px;
		height: 40px;
    .btn {
      height: 30px;
    }
  }
}
</style>
