<template>
  <div class="panel">
    <div class="panel__content">
      <div class="panel__header">
        <h2 class="panel__title panel__title--minor">
          Top<br /><b>Produtos do mês atual</b>
        </h2>
      </div>
      <div class="tab-filter">
        <span class="tab-filter__title">Ordenar:</span>
        <ul class="tab-filter__tabs">
          <li class="tab-filter__tabs-item" :class="{ '--active': typeMedia == 'revenue' }" @click="setMedia('revenue')">
            Receita
          </li>
          <li class="tab-filter__tabs-item" :class="{ '--active': typeMedia == 'qtd_vendidos' }" @click="setMedia('qtd_vendidos')">
            Quantidade
          </li>
        </ul>
      </div>

      <div class="panel__body">

        <div class="empty" v-if="topProdutos.length === 0">
          <p>Carregando...</p>
        </div>

        <div v-if="topProdutos.length > 0">
          <!-- Mobile -->

          <div v-if="innerWidth < 991">
            <carousel
              :responsive="{
                0: { items: 1, dots: 0 },
              }"
            >
              <ul
                v-for="(produtoMobile, index) in topProdutosMobile"
                :key="index"
              >
                <li
                  class="product__content"
                  v-for="produto in produtoMobile"
                  :key="produto.productId"
                >
                  <ProductItem :produto="produto" />
                </li>
              </ul>
            </carousel>
          </div>

          <!-- Desktop -->

          <div class="products__list" v-if="innerWidth > 991">
            <!-- Loop Product -->
            <ProductItem 
              v-for="produto in topProdutos"
              :key="produto.productId"
              :produto="produto" />
            <!-- Loop Product -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import ProductItem from "./ProductItem.vue";
import { listarNumerosProdutos } from "@/api/dashboard";
export default {
  components: { 
    ProductItem,
    carousel
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      typeMedia: "revenue",
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    listarNumerosProdutos,
    onResize() {
      this.innerWidth = window.innerWidth;
    },
    setMedia(type) {
      this.typeMedia = type;
      this.listarNumerosProdutos(this.typeMedia);
    }
  },
  computed: {
    topProdutos() {
      return this.$store.state.dashboard.produtos;
    },
    topProdutosMobile() {
      let res = this.$store.state.dashboard.produtos;

      const arr = [];
      let arrInterno = [];
      res.map((item, index) => {
        const i = index + 1;

        if (i % 2 === 0) {
          arrInterno.push(item);
          arr.push(arrInterno);
          arrInterno = [];
        } else {
          arrInterno.push(item);
        }
      });

      return arr;
    },
  },
};
</script>

<style lang="scss">
.panel {
  height: 100%;
  flex: 1;
  max-width: 100%;

  .product {
    &__content {
      border-top: 1px solid $lightGray;
      display: flex;
      margin: 2.5vh 0 0;
      padding: 2.5vh 0 0;
    }

    &__description {
      width: 75%;
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        width: 65%;
        &:last-child {
          width: 30%;
        }
      }
    }

    &__infos {
      &.--qtd {
        align-items: center;
        display: flex;

        p {
          // @include font-size(1.7);
          font-size: 16px;
          margin-left: 10px;
        }
      }

      &.--lg {
        p {
          @include font-size(1.3);
        }
      }

      label {
        color: $cinza-interno;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      p {
        color: $cor-escura;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 10px;
        color: #404040;
        max-width: 21ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 350ms ease-in-out;
        cursor: pointer;
        &:hover {
          max-width: none;
          white-space: pre-line;
        }
      }
    }
  }

  .product__boxImage {
    display: flex;
    align-items: center;
    margin-right: 1.5vw;
  }

  .product__figure {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 8px solid $lightGray;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(2, 107, 126, 0.1);

    img {
      width: 100%;
    }
  }

  .product__name {
    min-width: 5.1rem;
    font-family: $font__main;
    @include font-size(0.82);
    color: $gray;
    display: block;
    line-height: 0.8vw;
  }

  .product__info {
    font-family: $font__main;
    @include font-size(0.76);
    color: $dark;
  }

  .product__qtd {
    font-family: $font__main;
    color: $dark;
    margin-left: 0;
    line-height: 2rem;
  }

  .product__highlight {
    display: flex;
  }

  .owl-carousel {
    .owl-item {
      &:first-child {
        .product__list {
          .product__content {
            &:first-child {
              .product__figure {
                border-color: #ffc400;
              }

              .product__boxImage {
                position: relative;

                &::before {
                  content: "";
                  width: 15px;
                  height: 30px;
                  position: absolute;
                  z-index: 1;
                  bottom: 0;
                  right: 0;
                  background: url("~@/assets/images/icones/icon-award.svg")
                    no-repeat center;
                  background-size: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .products__list > *:first-child {
    border: 0;

    .product__figure {
      border-color: #ffc400 !important;
    }

    .product__boxImage {
      position: relative;

      &::before {
        content: "";
        width: 35px;
        height: 50px;
        position: absolute;
        z-index: 1;
        bottom: -15px;
        right: -5px;
        background: url("~@/assets/images/icones/icon-award.svg") no-repeat
          center;
        background-size: cover;
      }
    }
  }

  .product__content {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .owl-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .owl-prev {
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      font-size: 0;
      &:hover {
        background-color: #f1f1f1;
      }
      &:before {
        content: "";
        background-image: url("~@/assets/images/icones/arrow.svg");
        width: 15px;
        height: 15px;
        position: relative;
        background-repeat: no-repeat;
        display: block;
        background-position: center;
      }
    }
    .owl-next {
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      font-size: 0;
      &:hover {
        background-color: #f1f1f1;
      }
      &:before {
        content: "";
        background-image: url("~@/assets/images/icones/arrow.svg");
        width: 15px;
        height: 15px;
        position: relative;
        background-repeat: no-repeat;
        display: block;
        transform: rotate(180deg);
        background-position: center;
      }
    }
  }
}
</style>
